<template>
  <div class="mt-5">
    <label for="answer" v-html="this.question" class="survey_label"></label>
    <fieldset class="space-y-1">
      <legend class="sr-only">Answers</legend>
      <div>
        <div
          v-for="answer in prepositions"
          :key="answer"
          class="relative flex items-start"
        >
          <div class="flex items-center h-7 mb-0.5">
            <input
              :checked="this.reply === answer.proposition"
              :id="answer.id"
              aria-describedby="comments-description"
              :name="name"
              :type="this.type"
              class="focus:ring-blue-500 h-4 w-4 primary_text border-blue-300 rounded"
              disabled
            />
          </div>
          <div class="ml-3">
            <label :for="answer.id" class="text-gray-700">{{
              answer.proposition
            }}</label>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="mt-4">
      <span class="text-red-500 font-bold">Answer:</span>
      <span class="ml-1 text-red-400 font-medium">{{ reply }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CheckMultipleAnswers",
  props: ["question", "mandatory", "id", "type", "name", "reply"],

  data() {
    return {
      checked: false,
      prepositions: [],
    };
  },
  methods: {
    getPrepositions() {
      axios
        .get(`${this.$cookie.getCookie("API")}/api/v1/surveyQuestions/${this.id}`)
        .then((response) => {
          if (response) {
            this.prepositions = response.data.survey_propositions;
          } else {
            this.prepositions = [];
          }
        })
        .catch((error) => {
          this.error = error.response;
          this.errorMsg = error.response.data.response;
          console.error(error.response);
        });
    },
  },
  mounted() {
    this.getPrepositions();
  },
};
</script>

<style></style>
