<template>
  <!-- Table -->
  <body class="flex items-center justify-center">
    <div class="container">
      <table
        class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed"
      >
        <thead :class="`divide-y divide-gray-200 text-white`">
          <tr
            :class="
              `body__table--header flex flex-col flex-no wrap sm:table-row sm:rounded-none mb-2 sm:mb-0`
            "
            v-for="reply in this.tablePaginatedData"
            :key="reply.id"
          >
            <th
              class="p-3 text-left uppercase text-sm"
              v-for="header in tableHeaders"
              :key="header.name"
            >
              <div
                class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
                @click="this.setSorting(header.sort, header.name)"
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
                  />
                </svg>
              </div>
            </th>
            <th class="w-40">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody class="flex-1 sm:flex-none divide-y divide-gray-200 body__table--body">
          <tr
            v-for="(reply, replyIdx) in this.tablePaginatedData"
            :key="reply.id"
            :class="[
              replyIdx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
              'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
            ]"
            @click="goToDetail(reply.id)"
          >
            <td class="p-3">
              <div class="inline-flex">
                <span class="body__table--body__text underline hover:text-malachite">{{
                  reply.score
                }}</span>
              </div>
            </td>
            <td class="p-3 truncate">
              <div class="mt-0 flex items-center text-white">
                <span class="body__table--body__text">{{
                  reply.survey_records.length
                }}</span>
              </div>
            </td>
            <td class="p-3 truncate">
              <div class="mt-0 flex items-center text-white">
                <span class="body__table--body__text">{{ reply.sent_at }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <BackendPagination
        :to="this.to"
        :from="this.from"
        :total="this.total"
        @firstPage="$emit('firstPage')"
        @previousPage="$emit('previousPage')"
        @nextPage="$emit('nextPage')"
        @lastPage="$emit('lastPage')"
      /> -->
    </div>
  </body>

  <!-- dialog msg -->
  <!-- <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog> -->
</template>

<script>
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  SortAscendingIcon,
} from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

// import ComfirmationDialog from "../components/ConfirmationDialog.vue";
// import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import BackendPagination from "../BackendPagination.vue";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  name: "SurveyReplyTable",
  props: [
    "tablePaginatedData",
    "backendPagination",
    "to",
    "from",
    "total",
    "lastPage",
  ],
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    SortAscendingIcon,
    BackendPagination,
  },
  data() {
    return {
      activeSorting: "",
      account,
      months,
      showMessage: false,
      messageToShow: "",
      askConfirmation: false,
      confirmationMessage: "",
      subMessageToShow: "",
      tableHeaders: [
        { name: "replySurveyTable.score", sort: "score" },
        { name: "replySurveyTable.survey_records", sort: "survey_records" },
        { name: "replySurveyTable.sent_at", sort: "sent_at" },
      ],
    };
  },
  methods: {
    setSorting(sort, header) {
      this.$emit("sorting", sort);
      this.activeSorting = header;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " à " +
        dateCreated.getHours() +
        ":" +
        dateCreated.getMinutes();

      return formatedDate;
    },

    goToDetail(id) {
      this.$router.push(`/survey-detail/${id}`);
    },

    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["active", "language"]),
  },
};
</script>

<style>
.priority_selection {
  padding-block-start: 20px;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    height: 50px;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  td {
    height: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 50px;
  }
}
</style>
