<template>
  <div class="mt-10 -z-10">
    <label for="answer" v-html="this.question"></label>
    <div class="mt-3 inline-block min-w-full">
      <div class="inline-flex min-w-full">
        <input
          type="range"
          name="answer"
          min="1"
          max="5"
          id="answer"
          :value="this.reply"
          class="flex-1 -z-10"
          disabled
        />

        <div class="ml-2">
          <span class="text-3xl" v-if="this.reply == 4">&#128512;</span>
          <span class="text-3xl" v-else-if="this.reply == 5">&#128513;</span>
          <span class="text-3xl" v-else-if="this.reply == 2">&#128542;</span>
          <span class="text-3xl" v-else-if="this.reply == 1">&#128546;</span>
          <span class="text-3xl" v-else>&#128528;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
export default {
  name: "ScaleOneToFive",
  props: ["question", "mandatory", "id", "reply"],
  components: {
    EmojiHappyIcon,
    EmojiSadIcon,
    QuestionMarkCircleIcon,
  },
};
</script>
