<template>
  <div class="mt-10">
    <h1 v-html="this.question" class="mb-1"></h1>
    <textarea
      readonly
      type="text"
      name="open-answer"
      id="open-answer"
      class="shadow-md block w-full sm:text-sm text-black border-gray-900 px-2"
      :placeholder="this.reply"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "OpenQuestion",
  props: ["question", "mandatory", "id", "reply"],
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
input {
  line-height: 4rem;
}
</style>
