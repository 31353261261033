<template>
  <div class="mt-10">
    <label for="answer" v-html="this.question"></label>
    <div class="mt-3 inline-block min-w-full">
      <div class="inline-flex min-w-full">
        <input
          type="range"
          min="1"
          max="10"
          :value="this.reply"
          name="answer"
          id="answer"
          class="flex-1"
          disabled
        />

        <div class="ml-2">
          <span class="text-3xl" v-if="this.reply >= 9">&#128512;</span>
          <span class="text-3xl" v-else-if="9 > this.reply && this.reply >= 6"
            >&#128513;</span
          >
          <span class="text-3xl" v-else-if="6 > this.reply && this.reply >= 4"
            >&#128542;</span
          >
          <span class="text-3xl" v-else-if="this.reply < 4">&#128546;</span>
          <span class="text-3xl" v-else>&#128528;</span>
        </div>

        <!-- <div v-if="reply < 5">
          <EmojiSadIcon
            :class="[
              reply > '2'
                ? 'ml-2 h-10 w-10 text-orange-500'
                : 'ml-2 h-10 w-10 text-red-500',
            ]"
            aria-hidden="true"
          />
        </div>
        <div v-else-if="reply >= 6">
          <EmojiHappyIcon
            :class="[
              reply < 9
                ? 'ml-2 h-10 w-10 text-green-500'
                : 'ml-2 h-10 w-10 text-blue-500',
            ]"
            aria-hidden="true"
          />
        </div>
        <div v-else>
          <QuestionMarkCircleIcon
            class="ml-2 h-10 w-10 text-gray-500"
            aria-hidden="true"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
export default {
  name: "ScaleOneToTen",
  props: ["question", "mandatory", "id", "reply"],
  components: {
    EmojiHappyIcon,
    EmojiSadIcon,
    QuestionMarkCircleIcon,
  },
  data() {
    return { answer: "5" };
  },
};
</script>
