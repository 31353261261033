<template>
  <div class="mt-10">
    <label for="answer" v-html="this.question"></label>
    <div v-if="reply !== 'n'">
      <RadioGroup v-model="selectedYes">
        <RadioGroupLabel class="sr-only">
          Answers
        </RadioGroupLabel>
        <div class="relative grid grid-cols-2 -space-y-px">
          <RadioGroupOption
            as="template"
            v-for="(answer, answerIdx) in answers"
            :key="answer.name"
            :value="answer"
            v-slot="{ checked, active }"
            disabled
          >
            <div
              :class="[
                answerIdx === 0 ? 'rounded-l-md' : '',
                answerIdx === answers.length - 1 ? 'rounded-r-md' : '',
                checked ? 'bg-gray-100 z-10 bg-opacity-50 ' : '',
                'relative p-4 flex flex-col cursor-pointer focus:outline-none',
              ]"
              @click="$emit('addAnswer', answer.value, this.id)"
            >
              <div class="flex items-center text-sm">
                <span
                  :class="[
                    checked ? 'bg-gray-600 border-transparent' : 'bg-white',
                    active ? 'ring-2 ring-offset-2 ring-gray-500' : '',
                    'h-4 w-4 rounded-full flex items-center justify-center',
                  ]"
                  aria-hidden="true"
                >
                  <span class="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroupLabel
                  as="span"
                  :required="this.mandatory ? true : false"
                  :class="[
                    checked ? 'text-indigo-900' : 'text-gray-900',
                    'ml-3 font-medium',
                  ]"
                  >{{ answer.name }}</RadioGroupLabel
                >
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
    <div v-else>
      <RadioGroup v-model="selectedNo">
        <RadioGroupLabel class="sr-only">
          Answers
        </RadioGroupLabel>
        <div class="relative grid grid-cols-2 -space-y-px">
          <RadioGroupOption
            as="template"
            v-for="(answer, answerIdx) in answers"
            :key="answer.name"
            :value="answer"
            v-slot="{ checked, active }"
            disabled
          >
            <div
              :class="[
                answerIdx === 0 ? 'rounded-l-md' : '',
                answerIdx === answers.length - 1 ? 'rounded-r-md' : '',
                checked ? 'bg-gray-100 z-10 bg-opacity-50 ' : '',
                'relative p-4 flex flex-col cursor-pointer focus:outline-none',
              ]"
              @click="$emit('addAnswer', answer.value, this.id)"
            >
              <div class="flex items-center text-sm">
                <span
                  :class="[
                    checked ? 'bg-gray-600 border-transparent' : 'bg-white',
                    active ? 'ring-2 ring-offset-2 ring-gray-500' : '',
                    'h-4 w-4 rounded-full flex items-center justify-center',
                  ]"
                  aria-hidden="true"
                >
                  <span class="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroupLabel
                  as="span"
                  :required="this.mandatory ? true : false"
                  :class="[
                    checked ? 'text-indigo-900' : 'text-gray-900',
                    'ml-3 font-medium',
                  ]"
                  >{{ answer.name }}</RadioGroupLabel
                >
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
    <OptionsForSurveyAnswers
      @add-complement="this.addAnswer"
      :withPicture="this.withPicture"
      :withComplement="this.withComplement"
      :mandatory="this.mandatory"
      :id="this.id"
      placeholder="Write your reply here..."
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import OptionsForSurveyAnswers from "./OptionsForSurveyAnswers.vue";
const answers = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

export default {
  name: "YesOrNo",
  props: ["question", "mandatory", "id", "reply"],
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    OptionsForSurveyAnswers,
  },
  data() {
    return {
      withPicture: false,
      withComplement: false,
    };
  },
  setup() {
    const selectedYes = ref(answers[0]);
    const selectedNo = ref(answers[1]);

    return {
      answers,
      selectedYes,
      selectedNo,
    };
  },
  methods: {
    addAnswer(event) {
      this.$emit("addAnswer", event, this.id);
    },
  },
};
</script>
