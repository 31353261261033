<template>
  <!-- {{ this.surveyRecordsQuestions }} -->
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 pl-20 pr-20 pt-5 min-h-screen ml-20 mr-20 mt-10 mb-10"
  >
    <div class="px-4 py-5 sm:px-6 flex justify-between">
      <div class="inline-block">
        <h1>
          Reply from
          <span class="font-bold"
            >{{ contact.firstName }} {{ contact.lastName }}</span
          >
          at
          <span class="font-bold">{{ parseDate(replyDate) }}</span>
        </h1>
        <p v-if="surveyType.includes('Ticket')">
          Ticket:
          <a
            @click="goToTicket(ticketId, ticketStatus)"
            class="underline hover:text-gray-600 font-bold"
            href="javascript:void(0)"
            >{{ ticketSubject }}</a
          >
        </p>
      </div>
      <div class="inline-block">
        <p class="text-gray-600">
          Survey type:
          <span class="">{{ surveyType }}</span>
        </p>
        <p class="text-gray-600">
          Assigned to:
          <span class="text-gray-800 font-bold">{{ userName }}</span>
        </p>
      </div>
      <!-- Content goes here -->
      <!-- We use less vertical padding on card headers on desktop than on body sections -->
    </div>
    <div class="px-4 py-5 sm:p-6">
      <div class="survey_card">
        <div
          class="items-center justify-center bg-gray-50 pt-10 pb-5 px-4 sm:px-6 lg:px-8 card card-height mb-0"
        >
          <div class="text-xl font-medium">
            <ul>
              <li
                v-for="(question, idx) in this.surveyRecordsQuestions"
                :key="question.id"
              >
                <div v-if="question.questionType === 'openQuestion'">
                  <OpenQuestion
                    :question="question.question"
                    :id="question.id"
                    :reply="replies[idx]"
                  />
                </div>
                <div v-if="question.questionType === 'scaleOneToFive'">
                  <ScaleOneToFive
                    :question="question.question"
                    :id="question.id"
                    :reply="replies[idx]"
                  />
                </div>

                <div v-if="question.questionType === 'scaleOneToTen'">
                  <ScaleOneToTen
                    :question="question.question"
                    :id="question.id"
                    :reply="replies[idx]"
                  />
                </div>
                <div v-if="question.questionType === 'consent'">
                  <Consent
                    :question="question.question"
                    :id="question.id"
                    :withPicture="withPicture"
                    :reply="replies[idx]"
                  />
                </div>
                <div v-if="question.questionType === 'checkbox'">
                  <CheckMultipleAnswers
                    :question="question.question"
                    :id="question.id"
                    :name="question.id"
                    type="checkbox"
                    :reply="replies[idx]"
                  />
                </div>
                <div v-if="question.questionType === 'multipleChoice'">
                  <CheckMultipleAnswers
                    :question="question.question"
                    :id="question.id"
                    :name="question.id"
                    type="radio"
                    :reply="replies[idx]"
                  />
                </div>
                <div v-if="question.questionType === 'yesOrNo'">
                  <YesOrNo
                    :question="question.question"
                    :id="question.id"
                    :reply="replies[idx]"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import SurveyRepliesDetailTable from "../../components/surveys/SurveyRepliesDetailTable.vue";

import OpenQuestion from "../../components/surveys/surveyQuestions/OpenQuestion.vue";
import ScaleOneToFive from "../../components/surveys/surveyQuestions/ScaleOneToFive.vue";
import ScaleOneToTen from "../../components/surveys/surveyQuestions/ScaleOneToTen.vue";
import Consent from "../../components/surveys/surveyQuestions/Consent.vue";
import CheckMultipleAnswers from "../../components/surveys/surveyQuestions/CheckMultipleAnswers.vue";
import YesOrNo from "../../components/surveys/surveyQuestions/YesOrNo.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["id", "page"],
  components: {
    SurveyRepliesDetailTable,
    OpenQuestion,
    ScaleOneToFive,
    ScaleOneToTen,
    Consent,
    CheckMultipleAnswers,
    YesOrNo,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      surveyReplies: [],
      surveyRecords: [],
      surveyRecordsQuestions: [],
      currentPage: 1,
      replies: [],
      status: [],
      contact: {},
      surveyType: "",
      userName: "",
      ticketId: "",
      ticketSubject: "",
      ticketStatus: "",
      replyDate: "",
    };
  },
  methods: {
    parseDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let sliceDate = date.split(" ");
      let newDate = new Date(sliceDate[0] + "T" + sliceDate[1]);
      return newDate.toLocaleDateString("fr-FR", options);
    },
    async getSurveyReplies() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/replyToSurveyRequests?page=${this.page}&filter[replied]=true`
        );

        this.surveyReplies = res.data.data;
        return res.data.data.filter((survey) => {
          return survey.id == this.id;
        });
      } catch (error) {
        this.isLoading = false;
        console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
      }
    },
    async getRecords() {
      try {
        const res = await this.getSurveyReplies();
        this.surveyType = res[0].surveyable_type;
        this.replyDate = res[0].sent_at;
        this.getContact(res[0].contact_id);
        if (res[0].surveyable_type.includes("Ticket")) {
          this.getTicket(res[0].surveyable_id);
        }
        return res[0].survey_records;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
      }
    },
    async getQuestionsId() {
      const res = await this.getRecords();
      let ids = [];
      for (let index = 0; index < res.length; index++) {
        // this.surveyRecordsQuestions.push(res[index].question_id);
        ids.push(res[index].question_id);
        this.replies.push(res[index].reply);
      }
      return ids;
    },
    async getQuestions() {
      let questions = [];
      const res = await this.getQuestionsId();
      for (let index = 0; index < res.length; index++) {
        try {
          const question = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/surveyQuestions/${res[index]}`);
          this.surveyRecordsQuestions.push(question.data);
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      }
      this.isLoading = false;
    },
    async getContact(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/contacts/${id}`
        );
        this.contact = res.data;
      } catch (error) {
        console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
      }
    },
    async getTicket(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (res) {
            this.ticketSubject = res.data.subject;
            this.ticketId = res.data.id;
            this.ticketStatus = res.data.status_id;
            this.getUser(res.data.assignee_id);
          }
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      }
    },
    async getUser(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.userName = res.data.name;
      } catch (error) {
        console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
      }
    },
    async getStatus() {
      try {
        const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.status = res.data;
      } catch (error) {
        console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
      }
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },
    goToTicket(id, status) {
      let statusName = this.checkStatus(status);
      let routeData = this.$router.resolve({
        path: `/tickets/${id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.getQuestions();
    this.getStatus();
  },
};
</script>

<style></style>
